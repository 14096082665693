import { RouteRecordRaw, RouteLocation } from 'vue-router'
import SectionsChildRoutes from '@/router/sections'
import exportsChildRoutes from '@/router/exports'
import projectParamAssessmentRoutes from '@/router/project-param-assessments'

const routes: Array<RouteRecordRaw> = [
  {
    path: 'sections',
    name: 'control-panel.sections',
    component: () => import('@/views/control-panel/projects/project/Sections.vue'),
    meta: { title: 'Alle deelprojecten' },
    redirect: (to: RouteLocation) => ({ name: 'map.sections', params: to.params }),
    children: SectionsChildRoutes
  },
  {
    path: 'exports',
    name: 'control-panel.exports',
    component: () => import('@/views/control-panel/projects/project/Exports.vue'),
    meta: { title: 'Alle exports' },
    redirect: (to: RouteLocation) => ({ name: 'control-panel.exports.list', params: to.params }),
    children: exportsChildRoutes
  },
  {
    path: 'edit',
    name: 'control-panel.project.edit',
    component: () => import('@/views/control-panel/projects/project/Edit.vue'),
  },
  {
    path: 'param-assessments',
    name: 'project.param-assessments',
    component: () => import('@/views/control-panel/projects/project/ParamAssessments.vue'),
    redirect: () => ({ name: 'project.param-assessments.list' }),
    children: projectParamAssessmentRoutes
  }
]

export default routes
