import { RouteRecordRaw } from 'vue-router'
import MeasurePointsChildRoutes from '@/router/measure-points'
import locationParamAssessmentRoutes from '@/router/location-param-assessments'

const routes: Array<RouteRecordRaw> = [
  {
    path: 'edit',
    name: 'measure-location.edit',
    component: () => import('@/views/control-panel/projects/project/sections/section/measure-locations/measure-location/Edit.vue'),
  },
  {
    path: 'measure-points',
    name: 'measure-points',
    component: () => import('@/views/control-panel/projects/project/sections/section/measure-locations/measure-location/MeasurePoints.vue'),
    // redirect: (to: RouteLocation) => ({ name: 'measure-points.map', params: to.params }),
    children: MeasurePointsChildRoutes
  },
  {
    path: 'param-assessments',
    name: 'location.param-assessments',
    component: () => import('@/views/control-panel/projects/project/sections/section/measure-locations/measure-location/ParamAssessments.vue'),
    redirect: () => ({ name: 'location.param-assessments.list' }),
    children: locationParamAssessmentRoutes
  },
  {
    path: 'concentration-table',
    name: 'location.concentration-table',
    redirect: (to) => ({ name: 'location.concentration-table.period', params: to.params }),
    component: () => import('@/views/control-panel/projects/project/sections/section/measure-locations/measure-location/ConcentrationTable.vue'),
    children: [
      {
        path: 'period',
        name: 'location.concentration-table.period',
        component: () => import('@/views/control-panel/projects/project/sections/section/measure-locations/measure-location/concentration-table/Period.vue'),
      }
    ]
  },
  {
    path: 'charts',
    name: 'location.charts',
    redirect: (to) => ({ name: 'location.chart.components', params: to.params }),
    component: () => import('@/views/control-panel/projects/project/sections/section/measure-locations/measure-location/Charts.vue'),
    children: [
      {
        path: 'components',
        name: 'location.chart.components',
        component: () => import('@/views/control-panel/projects/project/sections/section/measure-locations/measure-location/charts/Components.vue'),
      }
    ]
  }
]

export default routes
